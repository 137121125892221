<template>
    <form @submit="onSubmit" class="add-form">
      <div class="form-control">
        <label>Task</label>
        <input 
          id="input-addtask-text"
          type="text" 
          v-model="text" 
          name="text" 
        />
      </div>
      <div class="form-control">
        <label>Date</label>
        <input
          id="input-addtask-date"
          type="date" 
          v-model="date" 
          name="date"/>
        <label>Time</label>
        <input 
          id="input-addtask-time"
          type="time" 
          v-model="time" 
          name="time"/>
      </div>

      <div class="form-control form-control-check">
        <label>Set Repeat</label>
        <input type="checkbox" v-model="repeatstatus" id="repeatcheckbox" name="repeatstatus" @change="showHideRepeat"/>
      </div>

      <div v-show="visRepeat"> 
            <input type="radio" id="repeat-annually" name="repeat-freq"
                value="annually" style="margin-left: 15px;" @click="setRepeatFreq('annually')">
            <label for="repeat-annually" style="padding-left: 10px;">Annually</label>

            <input type="radio" id="repeat-monthly" name="repeat-freq" 
                value="monthly" style="margin-left: 15px;" @click="setRepeatFreq('monthly')">
            <label for="repeat-monthly" style="padding-left: 10px;">Monthly</label>

            <input type="radio" id="repeat-weekly" name="repeat-freq" 
                value="weekly" style="margin-left: 15px;" @click="setRepeatFreq('weekly')">
            <label for="repeat-weekly" style="padding-left: 10px;">Weekly</label>

            <input type="radio" id="repeat-daily" name="repeat-freq" 
                value="daily" style="margin-left: 15px;" @click="setRepeatFreq('daily')">
            <label for="repeat-daily" style="padding-left: 10px;">Daily</label>
      </div>

      <div class="form-control form-control-check">
        <br>
        <br>
        <label>Set Reminder</label>
        <input type="checkbox" v-model="remindstatus" id="remindcheckbox" name="remindstatus" @change="showHideRemind"/>
      </div>

      <div v-show="visRemind">

        <input type="checkbox" v-model="onemonthremind" name="onemonthremind" id="onemonthremind" style="margin-left: 15px;"/>
        <label style="padding-left: 10px;">1-month</label>
        <input type="checkbox" v-model="oneweekremind" name="oneweekremind" id="oneweekremind" style="margin-left: 15px;"/>
        <label style="padding-left: 10px;">1-week</label>
        <input type="checkbox" v-model="twodaysremind" name="twodaysremind" id="twodaysremind" style="margin-left: 15px;"/>
        <label style="padding-left: 10px;">2-days</label>
        <input type="checkbox" v-model="onedayremind" name="onedayremind" id="onedayremind" style="margin-left: 15px;"/>
        <label style="padding-left: 10px;">1-day</label>
        <br>
        <br>
        <Multiselect
          v-model="remindusers"
          mode="tags"
          placeholder="Select user(s) to remind"
          :options="tagOptions"
          :search="true"
        />
        <br>
      </div>


      <input type="submit" value="Save Task" class="btn btn-block" />
    </form>  
    
</template>
  
<script>
import Multiselect from '@vueform/multiselect'
export default {
  name: 'AddTask',
  components: {Multiselect},
  data() {
    return {
      id: '',
      text: '',
      date: '',
      time: '',
      remindstatus: false,
      remindperiods: [],
      remindedperiods: [],
      remindusers: [],
      unremindusers: [],
      repeatstatus: false,
      repeatfreq: '',
      repeatann: false,
      repeatmon: false,
      repeatwee: false,
      repeatdai: false,
      
      visRepeat: false,
      visRemind: false,
      creator: '',
      // tOptions: tagOptions,
      //value: [],
      //2BModified: synce this with App
      onemonthremind: false,
      oneweekremind: false,
      twodaysremind: false,
      onedayremind: false,
      
    }
  },
  props: {
          username: String,
          tagOptions: Object,
          editMode: {
            type: Number,
            default: 0,
          },
          focusTask: {
                id: '',
                text: '',
                date: '',
                time: '',
                remindstatus: false,
                remindperiods: [],
                remindedperiods: [],
                remindusers: [],
                unremindusers: [],
                repeatstatus: false,
                repeatfreq: '',
                creator: ''
          }
          
  },
  watch: {
    editMode() {
      // console.log('inside AddTask ',this.editMode)
      if (this.editMode==1) {
          // console.log("edit mode: " + this.editMode)
          this.id = this.focusTask.id
          this.text = this.focusTask.text
          this.date = this.focusTask.datetime.split(' ')[0]
          this.time = this.focusTask.datetime.split(' ')[1]

          this.repeatstatus = this.focusTask.repeatstatus
          this.repeatann = this.focusTask.repeatfreq == 'annually'
          this.repeatmon = this.focusTask.repeatfreq == 'monthly'
          this.repeatwee = this.focusTask.repeatfreq == 'weekly'
          this.repeatdai = this.focusTask.repeatfreq == 'daily'
          if (this.repeatann) document.getElementById('repeat-annually').checked = "checked"
          else if (this.repeatmon) document.getElementById('repeat-monthly').checked = "checked"
          else if (this.repeatwee) document.getElementById('repeat-weekly').checked = "checked"
          else if (this.repeatdai) document.getElementById('repeat-daily').checked = "checked"
          this.visRepeat = this.repeatstatus

          this.remindstatus = this.focusTask.remindstatus
          // console.log('Before loading remind periods')
          // console.log(this.onedayremind)
          this.onemonthremind = this.focusTask.remindperiods.includes('1mremind')
          this.oneweekremind = this.focusTask.remindperiods.includes('1wremind')
          this.twodaysremind = this.focusTask.remindperiods.includes('2dremind')
          this.onedayremind = this.focusTask.remindperiods.includes('1dremind')
          // console.log('After loading remind periods')
          // console.log(this.onedayremind)
          // console.log(this.focusTask.remindperiods)
          if (this.onemonthremind) document.getElementById('onemonthremind').checked = "checked"
          if (this.oneweekremind) document.getElementById('oneweekremind').checked = "checked"
          if (this.twodaysremind) document.getElementById('twodaysremind').checked = "checked"
          if (this.onedayremind) document.getElementById('onedayremind').checked = "checked"

          this.remindusers = this.focusTask.remindusers
          this.unremindusers = this.focusTask.unremindusers
          this.visRemind = this.remindstatus

          this.creator = this.focusTask.creator
      } 
      // else {console.log("No action here!")}
    }
  },


  methods: {
    setRepeatFreq(val){
      if (val == 'annually') {
        this.repeatann = true
        this.repeatmon = false
        this.repeatwee = false
        this.repeatdai = false
      } else if(val == 'monthly') {
        this.repeatann = false
        this.repeatmon = true
        this.repeatwee = false
        this.repeatdai = false
      } else if(val == 'weekly') {
        this.repeatann = false
        this.repeatmon = false
        this.repeatwee = true
        this.repeatdai = false
      } else if(val == 'daily') {
        this.repeatann = false
        this.repeatmon = false
        this.repeatwee = false
        this.repeatdai = true
      }
      // console.log(this.repeatann)
      // console.log(this.repeatmon)
      // console.log(this.repeatdai)
    },
    onSubmit(e) {
      e.preventDefault()

      if (!this.text | !this.date | !this.time) {
        alert('Please fill in task, date, and time!!')
        return
      }

      // if (document.getElementById('addtask-pane').checked == 'checked') {
      //   //this is for the case when we simple add new task, not by editing existing one!!!
      //   //2BModified: this must get the new taskid which is 1-increment of max taskids in Dynamodb
      //   this.id = Math.floor(Math.random()*1000000)
      // }

      let listRemindPeriods = []
      if (this.onemonthremind) {
        listRemindPeriods.push('1mremind')
      }
      if (this.oneweekremind) {
        listRemindPeriods.push('1wremind')
      }
      if (this.twodaysremind) {
        listRemindPeriods.push('2dremind')
      }
      if (this.onedayremind) {
        listRemindPeriods.push('1dremind')
      }
      if (this.remindstatus & listRemindPeriods.length == 0) {
        alert("Please select at least one remind type!!")
        return 
      }
      if (this.remindstatus & this.remindusers.length == 0) {
        alert("Please select at least one user to be reminded!!")
        return 
      }
      
      if (this.repeatann) {
        this.repeatfreq = 'annually'
      } else if (this.repeatmon) {
        this.repeatfreq = 'monthly'
      } else if (this.repeatwee) {
        this.repeatfreq = 'weekly'
      } else if (this.repeatdai) {
        this.repeatfreq = 'daily'
      }
      if (this.repeatstatus & this.repeatfreq == '') {
        alert("Please select at repeat frequency!!")
        return 
      }

      const isNotEditMode = document.getElementById("edittask-pane").checked == false
      
      const newTask = {
        id: isNotEditMode ? 'xxx' : this.id,
        text: this.text,
        datetime: isNotEditMode ? this.date + " " + this.time : this.date + " " + this.time + '_' + this.focusTask.creator + '_' + this.focusTask.id,
        remindstatus: this.remindstatus,
        remindperiods: listRemindPeriods,
        remindedperiods: this.remindedperiods,
        repeatstatus: this.repeatstatus,
        repeatfreq: this.repeatfreq,
        remindusers: this.remindusers.includes('All Users') ? this.tagOptions.filter((x)=>x != 'All Users') : this.remindusers,
        unremindusers: this.unremindusers,
        creator: this.editMode == 0 ? this.username : this.creator
      }

      // this.$emit('add-task', newTask)

      this.text = ''
      this.date = ''
      this.time = ''
      this.remindstatus = false
      this.repeatstatus = false

      this.visRemind = false
      this.visRepeat = false

      document.getElementById("repeat-annually").checked = null
      document.getElementById("repeat-monthly").checked = null
      document.getElementById("repeat-weekly").checked = null
      document.getElementById("repeat-daily").checked = null
      document.getElementById("onemonthremind").checked = null
      document.getElementById("oneweekremind").checked = null
      document.getElementById("twodaysremind").checked = null
      document.getElementById("onedayremind").checked = null

      this.repeatfreq = ''
      this.remindedperiods = []
      this.remindusers = []
      this.unremindusers = []
      this.creator = ''

      this.$emit('add-task', newTask)
    },
    showHideRepeat() {
      this.visRepeat = !this.visRepeat
      if (!this.visRepeat) {
          document.getElementById("remind-annually").checked = null
          document.getElementById("remind-monthly").checked = null
          document.getElementById("remind-weekly").checked = null
          document.getElementById("remind-daily").checked = null
          this.remindann = false
          this.remindmon = false
          this.reminddai = false
      }
    },
    showHideRemind() {
      this.visRemind = !this.visRemind
      if (!this.visRemind) {
          this.remindusers = []
      }
      // console.log(this.remindusers)
    }
  },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}
</style>
