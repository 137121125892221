<template>
    <button :style="{background: color}" style="cursor: pointer" @click="onClick()">
        {{ text }}
    </button>

</template>

<script>
    // import { stringLiteral } from '@babel/types';
    export default {
        name: 'ButtonH',
        props: {
            text: String,
            color: String
        },
        methods: {
            onClick() {
                //window.alert("show/hide input form")
                this.$emit('btn-clicked')
            }
        }
    }
</script>

<style scoped>
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
</style>