<template>
  <div class="container">
    <HeaderH @toggle-add-task="toggleAddTask" @logout="logout" title="Task Tracker" :showForm="showForm" :isLoggedIn="!isLoggedIn"/>
    <div v-show="showForm"> 
      <div v-show="!isLoggedIn"> 
          <input type="radio" id="login-pane" name="select-pane" 
              value="login" style="margin-left: 20px;" @click="selectForm('login')">
          <label for="login-pane" style="padding-left: 10px; cursor: pointer;">Login</label>

          <input type="radio" id="signup-pane" name="select-pane" 
              value="signup" style="margin-left: 20px;" @click="selectForm('signup')">
          <label for="signup-pane" style="padding-left: 10px; cursor: pointer;">Signup</label>
      </div>
      <div v-show="isLoggedIn"> 
          <input type="radio" id="addtask-pane" name="select-pane" 
              value="addtask" style="margin-left: 20px;" @click="selectForm('addtask')">
          <label for="addtask-pane" style="padding-left: 10px; cursor: pointer;">Add Task</label>

          <input v-show = editMode type="radio" id="edittask-pane" name="select-pane" 
              value="edittask" style="margin-left: 20px; cursor: pointer;">
          <label v-show = editMode for="edittask-pane" style="padding-left: 10px;">Edit Task</label>
      </div>

      <AddTask @add-task="addTask" v-show="selectedAddTask" :editMode="editMode" :focusTask="focusTask" :username="username" :tagOptions="tagOptions"/>
      <LoginH @login="logIn" v-show="selectedLogin"/>
      <SignUp @signup="signUp" v-show="selectedSignup"/>
    </div>
    <br>
    <TasksH @toggle-reminder="toggleReminder" @delete-task="deleteTask" @edit-task="editTask" :tasks="tasks"/>
    <br>
    <div style="float: left;" @click="getPrevTasks()" v-show="isLoggedIn">    
        <label style="cursor: pointer;">Previous</label>  
    </div>
    <div style="float: right;" @click="getNextTasks()" v-show="isLoggedIn">    
        <label style="cursor: pointer;">Next</label>
    </div>

  </div>
  
</template>

<script>
    import HeaderH from './components/Header'
    import TasksH from './components/Tasks'
    import AddTask from './components/AddTask'
    import LoginH from './components/Login'
    import SignUp from './components/Signup'
    // import ButtonH from './components/Button'
    import store from './store'

    export default {
      name: 'App',
      components: {
        HeaderH,
        TasksH,
        AddTask,
        LoginH,
        SignUp,
        // ButtonH
      },
      data() {
        return {
          tasks: [],
          // minTaskId: 1,
          id: '0',
          showForm: true,
          isLoggedIn: false,
          // isEdit: true,
          editMode: 0,
          selectedLogin: true,
          selectedSignup: false,
          selectedAddTask: false,
          username: '',
          sesstoken: '',
          mode: 'gt',
          // actiontime: Date.now(),
          // inactthresh: 120 * 1000,
          anchdatetime: this.getFormatDate(new Date()),
          anchcreator: '', 
          anchid: '1',
          tagOptions: [],
          focusTask: {
            id: '0',
            text: '',
            datetime: 'yyyy-mm-dd HH:MM',
            remindstatus: false,
            remindperiods: [],
            remindedperiods: [],
            remindusers: [],
            unremindusers: [],
            repeatstatus: false,
            repeatfreq: "",
            creator: ''
          }
        }
        
      },
      
      async created() {
        // console.log('Initial page load (no tasks until logged in)')
        // console.log(this.anchdatetime)
        let stateSessData = store.getters.getSessionData
        // console.log(stateSessData)
        this.username = stateSessData.username
        this.sesstoken = stateSessData.sesstoken
        this.isLoggedIn = this.username != ""
        this.fetchTasks(this.username, this.mode, this.anchdatetime, this.anchcreator, this.anchid)
      },
      async mounted() {
        if (this.isLoggedIn) {
          this.showForm = true
          this.selectForm('addtask')
          document.getElementById("addtask-pane").checked = true
        } else{
          this.showForm = true
          this.selectForm('login')
          document.getElementById("login-pane").checked = true
        }
      },
      
      methods: {
        async updateStates(newData) {
          await this.$store.dispatch("updateSessionData", newData)
        },
        async getPrevTasks(){
          if (this.tasks != undefined && this.tasks.length > 0) {
            let oldTasks = [...this.tasks]
            let firstTask = structuredClone(this.tasks[0])
            if (firstTask.id.includes('#')) {
              firstTask.id = firstTask.id.split('#')[1]
            }
            await this.fetchTasks(this.username, 'lt', firstTask.datetime, firstTask.creator, firstTask.id)
            if (this.tasks.length == 0 || this.tasks == undefined) {
              alert("Sorry, no previous tasks to display!")
              this.tasks = oldTasks
            } else {
              console.log("Tasks loaded")
            }
          } else {
            // console.log(this.username, 'lt', this.getFormatDate(new Date()), this.username, "0")
            await this.fetchTasks(this.username, 'lt', this.getFormatDate(new Date()), this.username, "0")
          }
        },
        async getNextTasks(){
          if (this.tasks != undefined && this.tasks.length > 0) {
            let oldTasks = [...this.tasks]
            let finalTask = structuredClone(this.tasks[this.tasks.length-1])
            if (finalTask.id.includes('#')) {
              finalTask.id = finalTask.id.split('#')[1]
            }
            await this.fetchTasks(this.username, 'gt', finalTask.datetime, finalTask.creator, finalTask.id)
            console.log(this.tasks)
            if (this.tasks.length == 0 || this.tasks == undefined) {
              alert("Sorry, no next tasks to display!")
              this.tasks = oldTasks
            } else {
              console.log("Tasks loaded")
            }
          } else {
            await this.fetchTasks(this.username, 'gt', this.getFormatDate(new Date()), this.username, "0")
          }
        },
        selectForm(mode) {
            if (mode == 'login') {
              this.selectedLogin = true
              this.selectedSignup = false
              this.selectedAddTask = false
            } else if(mode == 'signup') {
              this.selectedLogin = false
              this.selectedSignup = true
              this.selectedAddTask = false
            } else if(mode == 'addtask') {
              this.selectedLogin = false
              this.selectedSignup = false
              this.selectedAddTask = true
            }
        },
        async toggleAddTask() {
            this.showForm = !this.showForm
        },
        async logIn(cred) {
            // console.log(cred)
            // console.log(JSON.stringify(cred))
            const options = {
                method: 'POST',
                url: 'https://tukts8qd1c.execute-api.us-east-1.amazonaws.com/dev/login',
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(cred)
            };
            let resp = await fetch('https://tukts8qd1c.execute-api.us-east-1.amazonaws.com/dev/login', options)
            let resp_data = await resp.json()
            // console.log("My json is: ", resp_data)
            if (resp_data.statusCode == 200) {
              let sess_data = resp_data
              this.username = sess_data.usr
              this.anchcreator = sess_data.usr
              this.anchid = "0"
              this.mode = 'gt'
              this.sesstoken = sess_data.token
              this.anchdatetime = this.getFormatDate(new Date())
              this.isLoggedIn = this.username != ""
              this.selectForm('addtask')
              this.showForm = true
              document.getElementById("addtask-pane").checked = "checked"
              this.fetchTasks(this.username, this.mode, this.anchdatetime, this.anchcreator, this.anchid)
              this.updateStates({username: this.username, sesstoken: this.sesstoken})
            } else {
              // //2BRefactored: This should be done after logout
              // this.username = ''
              // this.anchcreator = ''
              // this.sesstoken = ''
              // this.anchdatetime = this.getFormatDate(new Date())
              // this.fetchTasks(this.username, this.mode, this.anchdatetime, this.anchcreator, this.anchid)
              await this.logout()
              alert(resp_data.msg)
            }
        },
        async signUp(profile) {
            alert(profile)
        },
        async logout() {
            this.username = ''
            this.anchcreator = ''
            this.sesstoken = ''
            this.tasks = []
            this.updateStates({username: this.username, sesstoken: this.sesstoken})
            this.isLoggedIn = false
            this.selectForm('login')
            this.showForm = true
            document.getElementById("login-pane").checked = "checked"
        },
        async addTask(task) {
            task.setdate = task.datetime.split('_')[0]
            task.token = this.sesstoken
            // console.log('The following task is added!!')
            // console.log(typeof task, task)
            const options = {
                method: 'POST',
                url: 'https://tukts8qd1c.execute-api.us-east-1.amazonaws.com/dev/add-task',
                body: JSON.stringify(task),
                headers: {
                      "Content-Type": "application/json",
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                    }
            };
            let resp_data = await fetch('https://tukts8qd1c.execute-api.us-east-1.amazonaws.com/dev/add-task', options)
            .then(res => res.json())
            // console.log("New version of addtask api")
            // console.log(resp_data)
            if (resp_data.statusCode != 200) {
              // console.log(resp_data)
              await this.logout()
              alert("Session expired, Please log-in again!!!")
            } else { 
              this.editMode = 0
              document.getElementById('addtask-pane').checked = "checked"
              this.anchdatetime = this.getFormatDate(new Date())
              this.fetchTasks(this.username, this.mode, this.anchdatetime, this.anchcreator, this.anchid)
            }
        },
        async editTask(id) {
          if (!id.includes('#')) {
            await new Promise((resolve, reject) => {
              //console.log(id); 
              this.focusTask = this.tasks.filter((task) => task.id == id)[0]; 
              // console.log("Here is focusTask!!")
              // console.log(this.focusTask); 
              this.editMode = 1; 
              this.showForm = true; 
              this.id = id
              document.getElementById('addtask-pane').click(); 
              document.getElementById('edittask-pane').checked = 'checked'; 
              // console.log('hi', this.editMode);

              if (this.editMode == 1) {
                resolve("success")
              } else {
                reject("failure")
              }
              
            })
            // console.log('x = ', x) 
            
            const confEdit = confirm('Edit "' + this.focusTask.text.substring(0,20) + '..." ?');
            if (confEdit) {
              this.editMode = 2
              // console.log(this.focusTask)
              window.scrollTo(1,1)
            } else {
              this.editMode = 0
              this.showForm = true; 
              document.getElementById('addtask-pane').checked = "checked"
              this.focusTask = {
                                  id: 'taskid',
                                  text: '',
                                  datetime: 'yyyy-mm-dd HH:MM',
                                  remindstatus: false,
                                  remindperiods: [],
                                  remindedperiods: [],
                                  remindusers: [],
                                  unremindusers: [],
                                  repeatstatus: false,
                                  repeatfreq: "",
                                  creator: this.username
                              }
            }

          } else {
            alert('No Permission: you are only allowed to edit your tasks!!!')
          }
        },
        async deleteTask(id) {
            if (!id.includes('#')) {
              if (confirm('confirm task deletion?')) {
                const options = {
                    method: 'POST',
                    url: 'https://tukts8qd1c.execute-api.us-east-1.amazonaws.com/dev/delete-task',
                    body: JSON.stringify({
                      creator: this.username,
                      id: id,
                      token: this.sesstoken
                    }),
                    headers: {
                      "Content-Type": "application/json",
                      // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                };
                let deleteOutput = await fetch('https://tukts8qd1c.execute-api.us-east-1.amazonaws.com/dev/delete-task', options)
                .then(res => res.json())
                //console.log(deleteOutput)
                if (deleteOutput.statusCode != 200) {
                  // console.log(deleteOutput)
                  await this.logout()
                  alert("Session expired, Please log-in again!!!")
                } else { 
                  this.tasks = this.tasks.filter((task) => task.id !== id)
                }
                
              }     
            } else {
              alert('No Permission: you are only allowed to delete your tasks!!!')
            }
                  
        },
        async toggleReminder(id) {
            // console.log('inside toggleReminder',this.editMode)
            // console.log('inside toggleReminder',this.id)
            // console.log('inside toggleReminder',id)
            if (this.editMode==0 || this.id!=id) {
              this.tasks = this.tasks.map((task) => task.id == id ? {...task, remindstatus: !task.remindstatus} : task)
              if (id.includes('#')) {
                let tgltask = await this.tasks.filter((task)=> task.id==id)[0]
                // console.log('------------print task--------------')
                // console.log(tgltask)
                let targetURL = `https://tukts8qd1c.execute-api.us-east-1.amazonaws.com/dev/alter-remind-single-user?username=${this.username}&id=${id.split('#')[1]}&creator=${tgltask.creator}`
                // console.log(targetURL)
                await fetch(targetURL)
                // console.log(alterRemindSingleOutput)
              } else {
                let targetURL = `https://tukts8qd1c.execute-api.us-east-1.amazonaws.com/dev/alter-remind-status?id=${id}&creator=${this.username}`
                await fetch(targetURL)
                // console.log(alterRemindStatusOutput)
              }
            }
        },

        //REAL function to fetch tasks from AWS API Gateway
        async fetchTasks(username, mode, anchdatetime, anchcreator, anchid) {
          const options = {
                method: 'POST',
                url: 'https://tukts8qd1c.execute-api.us-east-1.amazonaws.com/dev/list-filtered-tasks',
                body: JSON.stringify({
                  username: username,
                  mode: mode,
                  anchdatetime: anchdatetime,
                  anchcreator: anchcreator,
                  anchid: anchid,
                  token: this.sesstoken
                }),
                headers: {
                  "Content-Type": "application/json",
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                },
            };
          
          let response = await fetch('https://tukts8qd1c.execute-api.us-east-1.amazonaws.com/dev/list-filtered-tasks', options)
            
          const fetch_object = await response.json();
          this.tagOptions = fetch_object['all_users']
          if (this.tagOptions != null) {
            this.tagOptions.push("All Users")
          }
          
          const allTasks = fetch_object['all_tasks']
          // console.log("all Tasks fetched => ", allTasks)
          for (let ind in allTasks) {
              if (allTasks[ind].creator != this.username) {
                allTasks[ind].id = allTasks[ind].creator + '#' + allTasks[ind].id
                allTasks[ind].remindusers = [this.username]
                if (allTasks[ind].unremindusers.includes(this.username)) {
                  allTasks[ind].remindstatus = false
                }
              }
          }
          this.tasks = allTasks
        },

        // //This is for fake database used in dev
        // async fetchTasksJServer() {
        //   let targetURL = "http://localhost:5000/tasks"

        //   const response = await fetch(targetURL);
        //   const allTasks = await response.json();
        //   return allTasks
        // },

        // async fetchTaskJServer(id) {
        //   const response = await fetch(`api/tasks/${id}`);
        //   const allTasks = await response.json();
        //   return allTasks
        // }
        
        getFormatDate(date) {    
          let month = '' + (date.getMonth()+1);
          let day = '' + date.getDate();
          let year = date.getFullYear();
          let hour = '' + date.getHours();
          let minute = '' + date.getMinutes();

          if (month.length < 2) 
              month = '0' + month;
          if (day.length < 2) 
              day = '0' + day;
          if (hour.length < 2) 
              hour = '0' + hour;
          if (minute.length < 2) 
              minute = '0' + minute;

          return [year, month, day].join('-') + ' ' + [hour, minute].join(':');
        }    
      },
      
      // //This is the second version (fake database using json-server)
      // async created() {
      //   this.tasks = await this.fetchTasksJServer()
      // }

      // //This is the first version (hard-coded)
      // created() {
      //     this.tasks = [
      //                   {
      //                     day: "2023-02-05 12:00",
      //                     id: 2,
      //                     reminder: false,
      //                     text: "dentist appointment"
      //                   }
      //                 ]
      // }
    }

</script>


  


<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Poppins', sans-serif;
}
.container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px;
}
.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}
.btn:focus {
  outline: none;
}
.btn:active {
  transform: scale(0.98);
}
.btn-block {
  display: block;
  width: 100%;
}
/* .h-list {
  display:inline;
  padding: 10px 40px;
} */
</style>
