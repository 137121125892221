<template>
    <header>
        <h1>{{ title }}</h1>
        <ButtonH 
            @btn-clicked="$emit('toggle-add-task')" 
            :text="showForm ? '\xa0' + 'Hide Form' + '\xa0' : '\xa0' + 'Show Form' + '\xa0'" 
            :color="showForm ? 'red' : 'green'"/>
        <ButtonH 
            @btn-clicked="$emit('logout')" 
            :disabled="isLoggedIn"
            :text="'\xa0'+'Logout '+'\xa0'" 
            :color="'yellow'"/>
    </header>
</template>

<script>
    import ButtonH from './Button'
    export default {
        name: 'HeaderH',
        props: {
            title: {
                type: String,
                default: "I hear TOO!!"
            },
            showForm: Boolean,
            isLoggedIn: Boolean
        },
        components: {
            ButtonH
        }
    }
</script>

<style scoped>
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
</style>