<template>
    <form @submit="onSubmit" class="login" autocomplete="off">
      <div class="form-control">
        <label>Username</label>
        <input 
          type="text" 
          v-model="usr" 
          name="username"/>
        <label>Password</label>
        <input 
          type="password" 
          v-model="pwd" 
          name="password"/>
      </div>
      <input type="submit" value="Login" class="btn btn-block" />
    </form>
  </template>
  
  <script>
  export default {
    name: 'LoginH',
    data() {
      return {
        usr: '',
        pwd: '',
      }
    },
    methods: {
      onSubmit(e) {
        e.preventDefault()
  
        if (!this.usr | !this.pwd) {
          alert('Please fill in username and password!!')
          return
        }

        const credential = {
            usr: this.usr,
            pwd: this.pwd
        }
  
        this.$emit('login', credential)
  
        this.usr = ''
        this.pwd = ''

      },
    },
  }
  </script>
  
  <style scoped>
  .add-form {
    margin-bottom: 40px;
  }
  
  .form-control {
    margin: 20px 0;
  }
  
  .form-control label {
    display: block;
  }
  
  .form-control input {
    width: 100%;
    height: 40px;
    margin: 5px;
    padding: 3px 7px;
    font-size: 17px;
  }
  
  .form-control-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .form-control-check label {
    flex: 1;
  }
  
  .form-control-check input {
    flex: 2;
    height: 20px;
  }
  </style>
  