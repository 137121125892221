<template>
    <div @dblclick="$emit('toggle-reminder', task.id)" :class="[task.remindstatus ? 'reminder' : '', task.id.includes('#') ? 'notmytask' : 'mytask']">
        <h3>
          {{ task.text }}
          <div>
            <i @click="$emit('edit-task',task.id)" class="fas fa-edit"></i>
            <i @click="$emit('delete-task',task.id)" class="fas fa-times fasdel"></i>
          </div>
          
        </h3>
        <p>{{ task.datetime }} &nbsp; &nbsp; <b style="font-size: 70%;">(ID: {{ task.id }}, Created by {{ task.creator }})</b></p>
    </div>
    
</template>

<script>
    export default {
        name: 'TaskH',
        props: {
            task: Object
        },
    }
</script>

<style scope>
.fas {
  color: rgb(94, 94, 254);
  margin-right: 8px;
}
.fasdel {
  color: rgb(247, 69, 69);
  margin-right: 8px;
}
.mytask {
  background: #b7e5f1;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
.notmytask {
  background: #f6e9bf;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
.reminder {
  border-left: 5px solid rgb(67, 249, 43);
}
h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>