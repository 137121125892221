<template>
    <div v-cloak :key="task.id" v-for="task in tasks">
        <TaskH @toggle-reminder="$emit('toggle-reminder', task.id)" 
            @delete-task="$emit('delete-task', task.id)"
            @edit-task="$emit('edit-task', task.id)"
        :task="task" />
    </div>
</template>

<script>
    import TaskH from './Task'
    export default {
        name: 'TasksH',
        props: {
            tasks: Array
        },
        components: {
            TaskH
        },
        emits: ['delete-task','toggle-reminder']
    }
</script>
