import { createStore } from "vuex";
import createPersistedState from "vuex-plugin-persistedstate";

const store = createStore({
  state: {
      "username": "",
      "sesstoken": ""
  },
  mutations: {
    updateSessionData(state, newData) {
      state.username = newData.username;
      state.sesstoken = newData.sesstoken;
    }
  },
  actions: {
    updateSessionData({ commit }, newData) {
      commit("updateSessionData", newData);
    }
  },
  getters: {
    getSessionData: state => ({username: state.username, sesstoken: state.sesstoken})
  },
  plugins: [createPersistedState()]

});

export default store