import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

// import jQuery from 'jquery'
// import router from './router'


// createApp(App)
//   .use(router)
//   .mount('#app')


createApp(App).use(store).mount('#app-en')
