<template>
    <form @submit="onSubmit" class="signup" autocomplete="off">
      <div class="form-control">
        <label>Username</label>
        <input 
          type="text" 
          v-model="username" 
          name="username"/>
        <label>Password</label>
        <input 
          type="text" 
          v-model="pwd" 
          name="password"/>
        <label>Email</label>
        <input 
          type="email" 
          v-model="email" 
          name="email"/>
        <label>Phone Number</label>
        <input 
          type="text" 
          v-model="phone" 
          name="phonenumber"/>
        <label>Line ID (no @)</label>
        <input 
          type="text" 
          v-model="line" 
          name="lineid"/>
      </div>
      <input type="submit" value="Signup" class="btn btn-block" />
    </form>
  </template>
  
  <script>
  export default {
    name: 'SignUp',
    data() {
      return {
        username: '',
        pwd: '',
        email: '',
        phone: '',
        line: ''
      }
    },
    methods: {
      onSubmit(e) {
        e.preventDefault()
  
        if (!this.username | !this.pwd | !this.line) {
          alert('Please fill in username, password and line id !!')
          return
        }

        const userProfile = {
            username: this.username,
            pwd: this.pwd,
            email: this.email,
            phone: this.phone,
            line: this.line,
        }
  
        this.$emit('signup', JSON.stringify(userProfile))
  
        this.username = ''
        this.pwd = ''
        this.email = ''
        this.phone = ''
        this.line = ''
      },
    },
  }
  </script>
  
  <style scoped>
  .add-form {
    margin-bottom: 40px;
  }
  
  .form-control {
    margin: 20px 0;
  }
  
  .form-control label {
    display: block;
  }
  
  .form-control input {
    width: 100%;
    height: 40px;
    margin: 5px;
    padding: 3px 7px;
    font-size: 17px;
  }
  
  .form-control-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .form-control-check label {
    flex: 1;
  }
  
  .form-control-check input {
    flex: 2;
    height: 20px;
  }
  </style>
  